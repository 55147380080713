.terms-of-service {
    align-items: flex-start;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 64px;
    position: relative;
  }
  
  .terms-of-service .frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 64px;
    position: relative;
    width: 100%;
  }
  
  .terms-of-service .div {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .terms-of-service .text-wrapper {
    align-self: stretch;
    color: var(--g-title);
    font-family: var(--heading-h3-font-family);
    font-size: var(--heading-h3-font-size);
    font-style: var(--heading-h3-font-style);
    font-weight: var(--heading-h3-font-weight);
    letter-spacing: var(--heading-h3-letter-spacing);
    line-height: var(--heading-h3-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .terms-of-service .text-wrapper-2 {
    align-self: stretch;
    color: var(--g-subtitle);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    text-align: center;
  }
  
  .terms-of-service .frame-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }
  
  .terms-of-service .element {
    align-self: stretch;
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    margin-top: -1px;
    position: relative;
  }
  
  .terms-of-service .text-wrapper-3 {
    align-self: stretch;
    color: #000000;
    font-family: var(--heading-h5-font-family);
    font-size: var(--heading-h5-font-size);
    font-style: var(--heading-h5-font-style);
    font-weight: var(--heading-h5-font-weight);
    letter-spacing: var(--heading-h5-letter-spacing);
    line-height: var(--heading-h5-line-height);
    position: relative;
  }
  
  .terms-of-service .text-wrapper-4 {
    align-self: stretch;
    color: #000000;
    font-family: var(--heading-h6-font-family);
    font-size: var(--heading-h6-font-size);
    font-style: var(--heading-h6-font-style);
    font-weight: var(--heading-h6-font-weight);
    letter-spacing: var(--heading-h6-letter-spacing);
    line-height: var(--heading-h6-line-height);
    margin-top: -1px;
    position: relative;
  }
  
  .terms-of-service .p {
    align-self: stretch;
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
  }
  