.privacy-policy {
    align-items: flex-start;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 64px;
    position: relative;
  }
  
  .privacy-policy .frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 64px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .div {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .text-wrapper {
    align-self: stretch;
    color: var(--g-title);
    font-family: var(--heading-h3-font-family);
    font-size: var(--heading-h3-font-size);
    font-style: var(--heading-h3-font-style);
    font-weight: var(--heading-h3-font-weight);
    letter-spacing: var(--heading-h3-letter-spacing);
    line-height: var(--heading-h3-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .privacy-policy .text-wrapper-2 {
    align-self: stretch;
    color: var(--g-subtitle);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    text-align: center;
  }
  
  .privacy-policy .frame-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .element {
    align-self: stretch;
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    margin-top: -1px;
    position: relative;
  }
  
  .privacy-policy .text-wrapper-3 {
    align-self: stretch;
    color: #000000;
    font-family: var(--heading-h5-font-family);
    font-size: var(--heading-h5-font-size);
    font-style: var(--heading-h5-font-style);
    font-weight: var(--heading-h5-font-weight);
    letter-spacing: var(--heading-h5-letter-spacing);
    line-height: var(--heading-h5-line-height);
    margin-top: -1px;
    position: relative;
  }
  
  .privacy-policy .p {
    align-self: stretch;
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
  }
  
  .privacy-policy .frame-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .frame-4 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 194px;
  }
  
  .privacy-policy .div-wrapper {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-light-grey);
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .text-wrapper-4 {
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .privacy-policy .frame-5 {
    align-items: center;
    align-self: stretch;
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--g-line);
    display: flex;
    gap: 8px;
    height: 112px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .text-wrapper-5 {
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .privacy-policy .frame-6 {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-lighter-grey);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--g-line);
    display: flex;
    gap: 8px;
    height: 112px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .frame-7 {
    align-items: center;
    align-self: stretch;
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--g-line);
    display: flex;
    gap: 8px;
    height: 160px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .frame-8 {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-lighter-grey);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--g-line);
    display: flex;
    gap: 8px;
    height: 184px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .frame-9 {
    align-items: center;
    align-self: stretch;
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--g-line);
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .frame-10 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  
  .privacy-policy .text-wrapper-6 {
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .privacy-policy .frame-11 {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-lighter-grey);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--g-line);
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .text-wrapper-7 {
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    margin-right: -6.5px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .privacy-policy .frame-12 {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-lighter-grey);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--g-line);
    display: flex;
    gap: 8px;
    height: 112px;
    overflow: hidden;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .text-wrapper-8 {
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    margin-right: -16px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .privacy-policy .text-wrapper-9 {
    color: #000000;
    flex: 1;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
  }
  
  .privacy-policy .span {
    color: #000000;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
  }
  
  .privacy-policy .frame-13 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 194px;
  }
  
  .privacy-policy .frame-14 {
    align-items: center;
    align-self: stretch;
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--g-line);
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .text-wrapper-10 {
    color: #000000;
    flex: 1;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    margin-top: -1px;
    position: relative;
  }
  
  .privacy-policy .frame-15 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  
  .privacy-policy .frame-16 {
    align-self: stretch;
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--g-line);
    flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }
  
  .privacy-policy .cookie-http {
    align-self: stretch;
    color: #000000;
    font-family: "Pretendard-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
  }
  
  .privacy-policy .text-wrapper-11 {
    text-decoration: underline;
  }
  
  .privacy-policy .frame-17 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 239px;
  }
  
  .privacy-policy .frame-18 {
    align-items: center;
    align-self: stretch;
    background-color: var(--g-light-grey);
    display: flex;
    gap: 8px;
    height: 40px;
    padding: 8px 16px;
    position: relative;
    width: 100%;
  }
  