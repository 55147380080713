.button {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  position: relative;
}

.button .text-wrapper {
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button .div {
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button .icon-relume {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.button .text-wrapper-2 {
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button.no-icon {
  gap: 8px;
}

.button.link {
  gap: 8px;
}

.button.primary {
  border: 1px solid;
}

.button.secondary {
  border: 1px solid;
}

.button.no-icon.small-true {
  padding: 8px 20px;
}

.button.only.tertiary {
  gap: 12px;
}

.button.secondary.dark-mode-true {
  border-color: var(--white);
}

.button.only.small-true {
  padding: 8px;
}

.button.secondary.only {
  gap: 12px;
}

.button.primary.only {
  gap: 12px;
}

.button.tertiary.trailing {
  gap: 12px;
}

.button.small-true.trailing {
  padding: 8px 20px;
}

.button.primary.dark-mode-false {
  background-color: var(--black);
  border-color: var(--black);
}

.button.primary.trailing {
  gap: 12px;
}

.button.tertiary.leading {
  gap: 12px;
}

.button.primary.dark-mode-true {
  background-color: var(--white);
  border-color: var(--white);
}

.button.small-true.leading {
  padding: 8px 20px;
}

.button.secondary.leading {
  gap: 12px;
}

.button.primary.leading {
  gap: 12px;
}

.button.secondary.dark-mode-false {
  border-color: var(--black);
}

.button.secondary.trailing {
  gap: 12px;
}

.button.secondary.small-false.leading {
  padding: 12px 24px;
}

.button.secondary.small-false.no-icon {
  padding: 12px 24px;
}

.button.small-false.tertiary.leading {
  padding: 12px 24px;
}

.button.primary.small-false.leading {
  padding: 12px 24px;
}

.button.secondary.small-false.only {
  padding: 12px;
}

.button.small-false.tertiary.trailing {
  padding: 12px 24px;
}

.button.secondary.small-false.trailing {
  padding: 12px 24px;
}

.button.primary.small-false.no-icon {
  padding: 12px 24px;
}

.button.primary.small-false.trailing {
  padding: 12px 24px;
}

.button.primary.small-false.only {
  padding: 12px;
}

.button.small-false.no-icon.tertiary {
  padding: 12px 24px;
}

.button.small-false.only.tertiary {
  padding: 12px;
}

.button.primary.dark-mode-true .text-wrapper {
  color: var(--black);
}

.button.secondary.dark-mode-true .text-wrapper {
  color: var(--white);
}

.button.dark-mode-true.tertiary .text-wrapper {
  color: var(--white);
}

.button.dark-mode-false.link .text-wrapper {
  color: var(--black);
}

.button.dark-mode-true.link .text-wrapper {
  color: var(--white);
}

.button.secondary.dark-mode-false .text-wrapper {
  color: var(--black);
}

.button.primary.dark-mode-false .text-wrapper {
  color: var(--white);
}

.button.dark-mode-false.tertiary .text-wrapper {
  color: var(--black);
}

.button.primary.dark-mode-true .div {
  color: var(--black);
}

.button.secondary.dark-mode-true .div {
  color: var(--white);
}

.button.dark-mode-true.tertiary .div {
  color: var(--white);
}

.button.dark-mode-false.link .div {
  color: var(--black);
}

.button.dark-mode-true.link .div {
  color: var(--white);
}

.button.secondary.dark-mode-false .div {
  color: var(--black);
}

.button.primary.dark-mode-false .div {
  color: var(--white);
}

.button.dark-mode-false.tertiary .div {
  color: var(--black);
}

.button.primary.dark-mode-true .text-wrapper-2 {
  color: var(--black);
}

.button.secondary.dark-mode-true .text-wrapper-2 {
  color: var(--white);
}

.button.dark-mode-true.tertiary .text-wrapper-2 {
  color: var(--white);
}

.button.dark-mode-false.link .text-wrapper-2 {
  color: var(--black);
}

.button.dark-mode-true.link .text-wrapper-2 {
  color: var(--white);
}

.button.secondary.dark-mode-false .text-wrapper-2 {
  color: var(--black);
}

.button.primary.dark-mode-false .text-wrapper-2 {
  color: var(--white);
}

.button.dark-mode-false.tertiary .text-wrapper-2 {
  color: var(--black);
}
